<template>
  <div>
    <h1>Vimeo Testing</h1>
    <b-form>
      <b-form-input
        v-model="title"
        type="text"
        placeholder="Video Title"
      />

      <b-form-file
        v-model="file"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />

      <b-button
        variant="outline-primary"
        @click="upload"
      >
        Upload Video
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {
  BForm, BFormInput, BFormFile, BButton,
} from 'bootstrap-vue'
import { Vimeo } from 'vimeo'

export default {
  components: {
    BForm,
    BFormInput,
    BFormFile,
    BButton,
  },
  data() {
    return {
      vim: null,
      title: '',
      file: null,
    }
  },
  computed: {
    clientID() {
      return process.env.VUE_APP_VIMEO_CLIENT_ID
    },

    clientSecret() {
      return process.env.VUE_APP_VIMEO_CLIENT_SECRET
    },

    accessToken() {
      return process.env.VUE_APP_VIMEO_ACCESS_TOKEN
    },
  },

  mounted() {
    this.vim = new Vimeo(this.clientID, this.clientSecret, this.accessToken)
  },
  methods: {
    tutorial() {
      return this.vim.request({
        method: 'GET',
        path: '/tutorial',
      }, (error, body, statusCode, headers) => {
        if (error) {
          console.log(error)
        }

        console.log(body, statusCode, headers)
      })
    },

    upload() {
      // https://developer.vimeo.com/api/upload/videos
      // https://vimeo.com/manage/videos
      // https://github.com/vimeo/vimeo.js/#uploading-videos

      // return this.vim.request({
      //   method: 'POST',
      //   path: '/me/videos',
      // }, (error, body, statusCode, headers) => {
      //   if (error) {
      //     console.log(error)
      //   }

      //   console.log(body, statusCode, headers)
      // })

      return this.vim.upload(
        this.file,
        // params
        {
          name: this.title,
        },
        uri => {
          console.log('File upload completed. Your Vimeo URI is:', uri)
        },
        (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          console.log(bytesUploaded, bytesTotal, `${percentage}%`)
        },
        error => {
          console.log(`Failed because: ${error}`)
        },
      )
    },
  },
}
</script>
